<template>
    <RouterView />
</template>

<script>
import '@/assets/tailwind.css';
export default {
    name: 'App',
};
</script>
