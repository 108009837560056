import { createWebHistory, createRouter } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'EmployeeHome',
        component: () => import('@/views/EmployeeHome'),
    },
    {
        path: '/employerHome',
        name: 'EmployerHome',
        component: () => import('@/views/EmployerHome'),
    },
    {
        path: '/login',
        name: 'EmployeeLogin2',
        component: () => import('@/views/EmployeeLogin'),
    },
    {
        path: '/employee-login',
        name: 'EmployeeLogin',
        component: () => import('@/views/EmployeeLogin'),
    },
    {
        path: '/employer-login',
        name: 'EmployerLogin',
        component: () => import('@/views/EmployerLogin'),
    },
    {
        path: '/employee-logout',
        name: 'EmployeeLogout',
        component: () => import('@/views/EmployeeLogout'),
    },
    {
        path: '/employer-logout',
        name: 'EmployerLogout',
        component: () => import('@/views/EmployerLogout'),
    },
    {
        path: '/employee-registration',
        name: 'EmployeeRegistration',
        component: () => import('@/views/EmployeeRegistration'),
    },
    {
        path: '/employerRegister',
        name: 'EmployerRegister',
        component: () => import('@/views/EmployerRegister'),
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/ContactPage'),
    },
    {
        path: '/profile',
        name: 'EmployeeProfile',
        component: () => import('@/views/EmployeeProfile'),
    },
    {
        path: '/offer',
        name: 'OfferList',
        component: () => import('@/views/OfferList'),
    },
    {
        path: '/offer/:id',
        name: 'OfferDetail',
        component: () => import('@/views/OfferDetail'),
    },
    {
        path: '/vacancy',
        name: 'VacancyList',
        component: () => import('@/views/VacancyList'),
    },
    {
        path: '/vacancy/:id/candidates',
        name: 'CandidateList',
        component: () => import('@/views/CandidateList'),
    },
    {
        path: '/vacancy/:vacancyId/candidates/:candidateId',
        name: 'CandidateDetail',
        component: () => import('@/views/CandidateDetail'),
    },
    {
        path: '/employeeRegistrationMessage',
        name: 'EmployeeRegistrationMessage',
        component: () => import('@/views/EmployeeRegistrationMessage'),
    },
    {
        path: '/employerRegistrationMessage',
        name: 'EmployerRegistrationMessage',
        component: () => import('@/views/EmployerRegistrationMessage'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFoundPage404',
        component: () => import('@/views/NotFoundPage404'),
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('@/views/PrivacyPage'),
    },
    {
        path: '/imprint',
        name: 'Imprint',
        component: () => import('@/views/ImprintPage'),
    },
    {
        path: '/forgotPassword',
        name: 'ForgotPassword',
        component: () => import('@/views/ForgotPassword'),
    },
    {
        path: '/newPassword',
        name: 'NewPassword',
        component: () => import('@/views/NewPassword'),
    },
    {
        path: '/confirm-email',
        name: 'ConfirmEmail',
        component: () => import('@/views/ConfirmEmail'),
    },
    {
        path: '/termsofuse',
        name: 'TermsOfUse',
        component: () => import('@/views/TermsOfUse'),
    },
    {
        path: '/businessconditions',
        name: 'BussinessConditions',
        component: () => import('@/views/BusinessConditions'),
    },
];

const router = createRouter({
    routes,
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
            };
        }
        return savedPosition || { top: 0 };
    },
});

export default router;
