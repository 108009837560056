import { createApp, defineAsyncComponent } from 'vue';
import App from './App.vue';

import '@/assets/tailwind.css';
import router from './router/router';
import 'aos/dist/aos.css';
import AOS from 'aos';

import "@/assets/style/GlobalStyle.css"

const FontAwesomeIcon = defineAsyncComponent(() => import('./fontawesome-icons.js'));

const app = createApp(App);

app.use(router);
app.config.unwrapInjectedRef = true;
app.AOS = new AOS.init();

app.component('fa', FontAwesomeIcon).mount('#app');
